import {createSlice} from "@reduxjs/toolkit";

const initialAgrovetsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  agrovetForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const agrovetsSlice = createSlice({
  name: "agrovets",
  initialState: initialAgrovetsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAgrovetById
    agrovetFetched: (state, action) => {
      state.actionsLoading = false;
      state.agrovetForEdit = action.payload.agrovetForEdit;
      state.error = null;
    },
    // findAgrovets
    agrovetsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAgrovet
    agrovetCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.agrovet);
    },
    // updateAgrovet
    agrovetUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.agrovet.id) {
          return action.payload.agrovet;
        }
        return entity;
      });
    },
    // deleteAgrovet
    agrovetDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteAgrovets
    agrovetsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // agrovetsUpdateState
    agrovetsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
