import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { usersSlice } from "../app/modules/Agriculture/_redux/users/usersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { farmersSlice } from "../app/modules/Agriculture/_redux/farmers/farmersSlice";
import { agrovetsSlice } from "../app/modules/Agriculture/_redux/agrovets/agrovetsSlice";
import { vouchersSlice } from "../app/modules/Agriculture/_redux/vouchers/vouchersSlice";
import { reportsSlice } from "../app/modules/Agriculture/_redux/reports/reportsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  users: usersSlice.reducer,
  farmers: farmersSlice.reducer,
  agrovets: agrovetsSlice.reducer,
  vouchers: vouchersSlice.reducer,
  reports: reportsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
