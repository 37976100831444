import agrovetTableMock from "./agrovetTableMock";
import MockUtils from "./mock.utils";

export default function mockAgrovet(mock) {
  mock.onPost("api/agrovets").reply(({ data }) => {
    const { agrovet } = JSON.parse(data);
    const {
      firstName = "",
      lastName = "",
      email = "",
      agrovetName = "",
      gender = "Female",
      status = 0,
      dateOfBbirth = "01/01/2019",
      ipAddress = "127.0.0.1",
      type = 1
    } = agrovet;

    const id = generateAgrovetId();
    const newAgrovet = {
      id,
      firstName,
      lastName,
      email,
      agrovetName,
      gender,
      status,
      dateOfBbirth,
      ipAddress,
      type
    };
    agrovetTableMock.push(newAgrovet);
    return [200, { agrovet: newAgrovet }];
  });

  mock.onPost("api/agrovets/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdAgrovets = mockUtils.baseFilter(
      agrovetTableMock,
      queryParams
    );
    return [200, filterdAgrovets];
  });

  mock.onPost("api/agrovets/deleteAgrovets").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = agrovetTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        agrovetTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/agrovets/updateStatusForAgrovets").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    agrovetTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/agrovets\/\d+/).reply(config => {
    const id = config.url.match(/api\/agrovets\/(\d+)/)[1];
    const agrovet = agrovetTableMock.find(el => el.id === +id);
    if (!agrovet) {
      return [400];
    }

    return [200, agrovet];
  });

  mock.onPut(/api\/agrovets\/\d+/).reply(config => {
    const id = config.url.match(/api\/agrovets\/(\d+)/)[1];
    const { agrovet } = JSON.parse(config.data);
    const index = agrovetTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    agrovetTableMock[index] = { ...agrovet };
    return [200];
  });

  mock.onDelete(/api\/agrovets\/\d+/).reply(config => {
    const id = config.url.match(/api\/agrovets\/(\d+)/)[1];
    const index = agrovetTableMock.findIndex(el => el.id === +id);
    agrovetTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateAgrovetId() {
  const ids = agrovetTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
