export default [
  {
      "id": 1,
      "firstName": "Robert",
      "lastName": "Kwalia",
      "email": "robertkwalia@example.com",
      "userName": "robertkwalia",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "06/18/1971",
      "ipAddress": "225.26.202.54",
      "type": 3,
      "_userId": 86,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 2,
      "firstName": "Grace",
      "lastName": "Kinyor",
      "email": "gracekinyor@example.com",
      "userName": "gracekinyor",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "01/04/1991",
      "ipAddress": "103.234.170.233",
      "type": 4,
      "_userId": 87,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 3,
      "firstName": "Xander",
      "lastName": "Cherotich",
      "email": "xandercherotich@example.com",
      "userName": "xandercherotich",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "02/07/1977",
      "ipAddress": "250.45.107.122",
      "type": 2,
      "_userId": 96,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 4,
      "firstName": "Ian",
      "lastName": "Mugo",
      "email": "ianmugo@example.com",
      "userName": "ianmugo",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "03/20/1983",
      "ipAddress": "31.174.96.122",
      "type": 3,
      "_userId": 63,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 5,
      "firstName": "Queen",
      "lastName": "Chepkorir",
      "email": "queenchepkorir@example.com",
      "userName": "queenchepkorir",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "01/02/1951",
      "ipAddress": "10.53.206.135",
      "type": 4,
      "_userId": 99,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 6,
      "firstName": "Ethan",
      "lastName": "Gwako",
      "email": "ethangwako@example.com",
      "userName": "ethangwako",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "03/24/1981",
      "ipAddress": "119.96.240.180",
      "type": 5,
      "_userId": 46,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 7,
      "firstName": "Jane",
      "lastName": "Mumbi",
      "email": "janemumbi@example.com",
      "userName": "janemumbi",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "10/21/1984",
      "ipAddress": "239.113.203.162",
      "type": 2,
      "_userId": 7,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 8,
      "firstName": "Oscar",
      "lastName": "Kotut",
      "email": "oscarkotut@example.com",
      "userName": "oscarkotut",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "11/21/1987",
      "ipAddress": "101.135.205.252",
      "type": 3,
      "_userId": 59,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 9,
      "firstName": "Angela",
      "lastName": "Loyanae",
      "email": "angelaloyanae@example.com",
      "userName": "angelaloyanae",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "10/12/1981",
      "ipAddress": "8.172.187.221",
      "type": 5,
      "_userId": 41,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 10,
      "firstName": "Timothy",
      "lastName": "Kasimili",
      "email": "timothykasimili@example.com",
      "userName": "timothykasimili",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "03/05/1995",
      "ipAddress": "33.208.63.106",
      "type": 2,
      "_userId": 38,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 11,
      "firstName": "Mary",
      "lastName": "Cheboi",
      "email": "marycheboi@example.com",
      "userName": "marycheboi",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "06/01/1990",
      "ipAddress": "21.42.73.225",
      "type": 2,
      "_userId": 56,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 12,
      "firstName": "Yvonne",
      "lastName": "Songok",
      "email": "yvonnesongok@example.com",
      "userName": "yvonnesongok",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "10/28/1988",
      "ipAddress": "142.25.167.201",
      "type": 5,
      "_userId": 3,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 13,
      "firstName": "Nina",
      "lastName": "Mwangangi",
      "email": "ninamwangangi@example.com",
      "userName": "ninamwangangi",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "05/19/1993",
      "ipAddress": "91.65.56.130",
      "type": 1,
      "_userId": 60,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 14,
      "firstName": "Yvonne",
      "lastName": "Songok",
      "email": "yvonnesongok@example.com",
      "userName": "yvonnesongok",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "09/21/1975",
      "ipAddress": "222.156.162.98",
      "type": 1,
      "_userId": 52,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 15,
      "firstName": "Diana",
      "lastName": "Makau",
      "email": "dianamakau@example.com",
      "userName": "dianamakau",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "03/18/1985",
      "ipAddress": "187.76.14.43",
      "type": 1,
      "_userId": 28,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 16,
      "firstName": "Nancy",
      "lastName": "Koskei",
      "email": "nancykoskei@example.com",
      "userName": "nancykoskei",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "11/17/1954",
      "ipAddress": "191.231.210.110",
      "type": 4,
      "_userId": 48,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 17,
      "firstName": "Peter",
      "lastName": "Chepkesis",
      "email": "peterchepkesis@example.com",
      "userName": "peterchepkesis",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "12/26/1958",
      "ipAddress": "81.216.238.179",
      "type": 3,
      "_userId": 83,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 18,
      "firstName": "Jack",
      "lastName": "Kiplitany",
      "email": "jackkiplitany@example.com",
      "userName": "jackkiplitany",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "08/05/1986",
      "ipAddress": "213.209.228.120",
      "type": 2,
      "_userId": 43,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 19,
      "firstName": "Michael",
      "lastName": "Kororia",
      "email": "michaelkororia@example.com",
      "userName": "michaelkororia",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "01/18/1971",
      "ipAddress": "16.12.24.160",
      "type": 5,
      "_userId": 31,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 20,
      "firstName": "Liam",
      "lastName": "Kipterege",
      "email": "liamkipterege@example.com",
      "userName": "liamkipterege",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "07/21/1969",
      "ipAddress": "163.102.164.241",
      "type": 2,
      "_userId": 56,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 21,
      "firstName": "Ursula",
      "lastName": "Cheptais",
      "email": "ursulacheptais@example.com",
      "userName": "ursulacheptais",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "10/28/1979",
      "ipAddress": "243.156.1.243",
      "type": 3,
      "_userId": 36,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 22,
      "firstName": "Wendy",
      "lastName": "Kibowen",
      "email": "wendykibowen@example.com",
      "userName": "wendykibowen",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "01/16/1991",
      "ipAddress": "231.44.204.239",
      "type": 4,
      "_userId": 32,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 23,
      "firstName": "Patrick",
      "lastName": "Ndungu",
      "email": "patrickndungu@example.com",
      "userName": "patrickndungu",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "10/03/1992",
      "ipAddress": "80.166.19.208",
      "type": 1,
      "_userId": 51,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 24,
      "firstName": "Diana",
      "lastName": "Gitahi",
      "email": "dianagitahi@example.com",
      "userName": "dianagitahi",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "02/03/1998",
      "ipAddress": "246.48.45.29",
      "type": 1,
      "_userId": 75,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 25,
      "firstName": "Ursula",
      "lastName": "Nyambura",
      "email": "ursulanyambura@example.com",
      "userName": "ursulanyambura",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "10/31/1969",
      "ipAddress": "89.106.182.47",
      "type": 3,
      "_userId": 71,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 26,
      "firstName": "Liam",
      "lastName": "Changeywo",
      "email": "liamchangeywo@example.com",
      "userName": "liamchangeywo",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "05/17/1971",
      "ipAddress": "144.24.73.191",
      "type": 3,
      "_userId": 49,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 27,
      "firstName": "George",
      "lastName": "Wanjiru",
      "email": "georgewanjiru@example.com",
      "userName": "georgewanjiru",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "04/05/1954",
      "ipAddress": "208.162.44.75",
      "type": 2,
      "_userId": 18,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 28,
      "firstName": "Frank",
      "lastName": "Kinyanjui",
      "email": "frankkinyanjui@example.com",
      "userName": "frankkinyanjui",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "12/07/1952",
      "ipAddress": "90.15.169.94",
      "type": 1,
      "_userId": 37,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 29,
      "firstName": "Sophia",
      "lastName": "Chemjor",
      "email": "sophiachemjor@example.com",
      "userName": "sophiachemjor",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "07/01/1957",
      "ipAddress": "11.152.234.113",
      "type": 2,
      "_userId": 26,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 30,
      "firstName": "Robert",
      "lastName": "Chepkosgei",
      "email": "robertchepkosgei@example.com",
      "userName": "robertchepkosgei",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "12/16/1974",
      "ipAddress": "205.118.255.175",
      "type": 4,
      "_userId": 93,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 31,
      "firstName": "Wendy",
      "lastName": "Lesuuda",
      "email": "wendylesuuda@example.com",
      "userName": "wendylesuuda",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "06/20/1959",
      "ipAddress": "201.225.218.241",
      "type": 1,
      "_userId": 99,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 32,
      "firstName": "Quincy",
      "lastName": "Kandie",
      "email": "quincykandie@example.com",
      "userName": "quincykandie",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "02/24/1955",
      "ipAddress": "160.140.10.163",
      "type": 5,
      "_userId": 83,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 33,
      "firstName": "Brian",
      "lastName": "Aprot",
      "email": "brianaprot@example.com",
      "userName": "brianaprot",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "11/27/1950",
      "ipAddress": "48.127.108.9",
      "type": 4,
      "_userId": 85,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 34,
      "firstName": "Jane",
      "lastName": "Mumbi",
      "email": "janemumbi@example.com",
      "userName": "janemumbi",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "04/15/1954",
      "ipAddress": "205.169.20.1",
      "type": 5,
      "_userId": 68,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 35,
      "firstName": "Chris",
      "lastName": "Eyapan",
      "email": "chriseyapan@example.com",
      "userName": "chriseyapan",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "11/27/1975",
      "ipAddress": "255.39.75.184",
      "type": 2,
      "_userId": 86,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 36,
      "firstName": "Peter",
      "lastName": "Chepkesis",
      "email": "peterchepkesis@example.com",
      "userName": "peterchepkesis",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "04/01/1965",
      "ipAddress": "151.180.99.17",
      "type": 1,
      "_userId": 4,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 37,
      "firstName": "Hannah",
      "lastName": "Jemaiyo",
      "email": "hannahjemaiyo@example.com",
      "userName": "hannahjemaiyo",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "01/26/1987",
      "ipAddress": "182.82.168.250",
      "type": 4,
      "_userId": 55,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 38,
      "firstName": "Patrick",
      "lastName": "Ndungu",
      "email": "patrickndungu@example.com",
      "userName": "patrickndungu",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "06/20/1954",
      "ipAddress": "1.33.82.100",
      "type": 1,
      "_userId": 55,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 39,
      "firstName": "Angela",
      "lastName": "Loyanae",
      "email": "angelaloyanae@example.com",
      "userName": "angelaloyanae",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "12/17/1984",
      "ipAddress": "35.129.161.76",
      "type": 1,
      "_userId": 28,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 40,
      "firstName": "Timothy",
      "lastName": "Kasimili",
      "email": "timothykasimili@example.com",
      "userName": "timothykasimili",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "08/22/1951",
      "ipAddress": "110.95.65.66",
      "type": 3,
      "_userId": 92,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 41,
      "firstName": "Queen",
      "lastName": "Chepkorir",
      "email": "queenchepkorir@example.com",
      "userName": "queenchepkorir",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "06/11/1979",
      "ipAddress": "225.84.8.198",
      "type": 1,
      "_userId": 18,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 42,
      "firstName": "Paul",
      "lastName": "Kitur",
      "email": "paulkitur@example.com",
      "userName": "paulkitur",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "10/02/1966",
      "ipAddress": "116.172.144.110",
      "type": 5,
      "_userId": 7,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 43,
      "firstName": "Brian",
      "lastName": "Kimobwa",
      "email": "briankimobwa@example.com",
      "userName": "briankimobwa",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "09/18/1964",
      "ipAddress": "29.210.186.188",
      "type": 3,
      "_userId": 6,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 44,
      "firstName": "Alice",
      "lastName": "Kimemia",
      "email": "alicekimemia@example.com",
      "userName": "alicekimemia",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "12/29/1951",
      "ipAddress": "214.60.37.107",
      "type": 4,
      "_userId": 10,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 45,
      "firstName": "Rachel",
      "lastName": "Kiyara",
      "email": "rachelkiyara@example.com",
      "userName": "rachelkiyara",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "07/29/1992",
      "ipAddress": "245.106.65.193",
      "type": 1,
      "_userId": 60,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 46,
      "firstName": "Patrick",
      "lastName": "Kamworor",
      "email": "patrickkamworor@example.com",
      "userName": "patrickkamworor",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "09/23/1982",
      "ipAddress": "40.185.62.112",
      "type": 4,
      "_userId": 49,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 47,
      "firstName": "Nancy",
      "lastName": "Koskei",
      "email": "nancykoskei@example.com",
      "userName": "nancykoskei",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "04/12/1977",
      "ipAddress": "205.227.166.151",
      "type": 4,
      "_userId": 32,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 48,
      "firstName": "Ursula",
      "lastName": "Cheptais",
      "email": "ursulacheptais@example.com",
      "userName": "ursulacheptais",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "10/04/1955",
      "ipAddress": "239.70.92.66",
      "type": 2,
      "_userId": 70,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 49,
      "firstName": "Ursula",
      "lastName": "Kataron",
      "email": "ursulakataron@example.com",
      "userName": "ursulakataron",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "09/20/1981",
      "ipAddress": "131.159.249.79",
      "type": 3,
      "_userId": 21,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 50,
      "firstName": "Isabella",
      "lastName": "Bethwell",
      "email": "isabellabethwell@example.com",
      "userName": "isabellabethwell",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "09/22/1979",
      "ipAddress": "193.224.160.135",
      "type": 5,
      "_userId": 80,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 51,
      "firstName": "Angela",
      "lastName": "Chirlee",
      "email": "angelachirlee@example.com",
      "userName": "angelachirlee",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "08/11/1951",
      "ipAddress": "102.165.72.104",
      "type": 4,
      "_userId": 77,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 52,
      "firstName": "Michael",
      "lastName": "Chenonge",
      "email": "michaelchenonge@example.com",
      "userName": "michaelchenonge",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "02/11/1969",
      "ipAddress": "213.70.248.135",
      "type": 4,
      "_userId": 84,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 53,
      "firstName": "Zachary",
      "lastName": "Kimeli",
      "email": "zacharykimeli@example.com",
      "userName": "zacharykimeli",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "05/31/1991",
      "ipAddress": "22.200.59.119",
      "type": 4,
      "_userId": 52,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 54,
      "firstName": "Thomas",
      "lastName": "Chemlany",
      "email": "thomaschemlany@example.com",
      "userName": "thomaschemlany",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "05/26/1953",
      "ipAddress": "106.30.194.223",
      "type": 3,
      "_userId": 69,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 55,
      "firstName": "Catherine",
      "lastName": "Kimurgor",
      "email": "catherinekimurgor@example.com",
      "userName": "catherinekimurgor",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "04/17/1992",
      "ipAddress": "123.200.246.4",
      "type": 3,
      "_userId": 24,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 56,
      "firstName": "Oliver",
      "lastName": "Kamau",
      "email": "oliverkamau@example.com",
      "userName": "oliverkamau",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "05/09/1951",
      "ipAddress": "159.178.33.16",
      "type": 4,
      "_userId": 65,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 57,
      "firstName": "Patrick",
      "lastName": "Kamworor",
      "email": "patrickkamworor@example.com",
      "userName": "patrickkamworor",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "12/27/1951",
      "ipAddress": "212.87.32.159",
      "type": 4,
      "_userId": 4,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 58,
      "firstName": "Rachel",
      "lastName": "Kaptich",
      "email": "rachelkaptich@example.com",
      "userName": "rachelkaptich",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "07/15/1955",
      "ipAddress": "107.9.86.185",
      "type": 5,
      "_userId": 50,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 59,
      "firstName": "Oscar",
      "lastName": "Chepkemei",
      "email": "oscarchepkemei@example.com",
      "userName": "oscarchepkemei",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "10/10/1988",
      "ipAddress": "11.219.147.3",
      "type": 3,
      "_userId": 8,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 60,
      "firstName": "Tina",
      "lastName": "Cheprot",
      "email": "tinacheprot@example.com",
      "userName": "tinacheprot",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "06/16/1957",
      "ipAddress": "199.252.132.250",
      "type": 5,
      "_userId": 64,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 61,
      "firstName": "Michael",
      "lastName": "Kororia",
      "email": "michaelkororia@example.com",
      "userName": "michaelkororia",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "08/11/1995",
      "ipAddress": "244.122.218.78",
      "type": 4,
      "_userId": 79,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 62,
      "firstName": "David",
      "lastName": "Ayabei",
      "email": "davidayabei@example.com",
      "userName": "davidayabei",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "09/03/1958",
      "ipAddress": "198.204.154.208",
      "type": 1,
      "_userId": 72,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 63,
      "firstName": "George",
      "lastName": "Jebiwott",
      "email": "georgejebiwott@example.com",
      "userName": "georgejebiwott",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "07/28/1992",
      "ipAddress": "56.253.216.65",
      "type": 4,
      "_userId": 29,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 64,
      "firstName": "Sophia",
      "lastName": "Kiyeng",
      "email": "sophiakiyeng@example.com",
      "userName": "sophiakiyeng",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "07/23/1952",
      "ipAddress": "178.135.222.255",
      "type": 5,
      "_userId": 30,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 65,
      "firstName": "Diana",
      "lastName": "Makau",
      "email": "dianamakau@example.com",
      "userName": "dianamakau",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "10/03/1968",
      "ipAddress": "157.107.103.121",
      "type": 5,
      "_userId": 47,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 66,
      "firstName": "Yvonne",
      "lastName": "Songok",
      "email": "yvonnesongok@example.com",
      "userName": "yvonnesongok",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "11/08/1977",
      "ipAddress": "230.46.99.240",
      "type": 2,
      "_userId": 40,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 67,
      "firstName": "Liam",
      "lastName": "Changeywo",
      "email": "liamchangeywo@example.com",
      "userName": "liamchangeywo",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "07/20/1991",
      "ipAddress": "11.87.45.24",
      "type": 3,
      "_userId": 99,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 68,
      "firstName": "Xander",
      "lastName": "Cherotich",
      "email": "xandercherotich@example.com",
      "userName": "xandercherotich",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "05/28/1985",
      "ipAddress": "160.168.183.221",
      "type": 1,
      "_userId": 76,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 69,
      "firstName": "Quincy",
      "lastName": "Ngeny",
      "email": "quincyngeny@example.com",
      "userName": "quincyngeny",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "09/02/1999",
      "ipAddress": "239.109.68.30",
      "type": 5,
      "_userId": 11,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 70,
      "firstName": "Robert",
      "lastName": "Kwalia",
      "email": "robertkwalia@example.com",
      "userName": "robertkwalia",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "12/08/1975",
      "ipAddress": "117.229.34.55",
      "type": 4,
      "_userId": 37,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 71,
      "firstName": "Fiona",
      "lastName": "Jebet",
      "email": "fionajebet@example.com",
      "userName": "fionajebet",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "02/03/1972",
      "ipAddress": "0.3.145.231",
      "type": 1,
      "_userId": 84,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 72,
      "firstName": "Catherine",
      "lastName": "Arusei",
      "email": "catherinearusei@example.com",
      "userName": "catherinearusei",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "06/11/1996",
      "ipAddress": "242.28.77.188",
      "type": 4,
      "_userId": 17,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 73,
      "firstName": "David",
      "lastName": "Kimwei",
      "email": "davidkimwei@example.com",
      "userName": "davidkimwei",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "11/30/1973",
      "ipAddress": "147.188.149.209",
      "type": 2,
      "_userId": 77,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 74,
      "firstName": "Hannah",
      "lastName": "Moiben",
      "email": "hannahmoiben@example.com",
      "userName": "hannahmoiben",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "10/02/1989",
      "ipAddress": "133.114.163.215",
      "type": 2,
      "_userId": 12,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 75,
      "firstName": "Victor",
      "lastName": "Oduya",
      "email": "victoroduya@example.com",
      "userName": "victoroduya",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "09/18/1961",
      "ipAddress": "18.151.17.231",
      "type": 1,
      "_userId": 98,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 76,
      "firstName": "Nancy",
      "lastName": "Koskei",
      "email": "nancykoskei@example.com",
      "userName": "nancykoskei",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "05/06/1983",
      "ipAddress": "234.218.5.164",
      "type": 1,
      "_userId": 3,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 77,
      "firstName": "Catherine",
      "lastName": "Arusei",
      "email": "catherinearusei@example.com",
      "userName": "catherinearusei",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "06/29/1961",
      "ipAddress": "103.248.171.162",
      "type": 2,
      "_userId": 34,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 78,
      "firstName": "Nathan",
      "lastName": "Cheboiboch",
      "email": "nathancheboiboch@example.com",
      "userName": "nathancheboiboch",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "11/25/1989",
      "ipAddress": "152.47.97.201",
      "type": 1,
      "_userId": 3,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 79,
      "firstName": "Xander",
      "lastName": "Cherotich",
      "email": "xandercherotich@example.com",
      "userName": "xandercherotich",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "09/24/1957",
      "ipAddress": "54.16.243.37",
      "type": 2,
      "_userId": 23,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 80,
      "firstName": "Brenda",
      "lastName": "Magut",
      "email": "brendamagut@example.com",
      "userName": "brendamagut",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "03/17/1995",
      "ipAddress": "243.133.8.4",
      "type": 2,
      "_userId": 60,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 81,
      "firstName": "Ursula",
      "lastName": "Larabal",
      "email": "ursulalarabal@example.com",
      "userName": "ursulalarabal",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "03/14/1960",
      "ipAddress": "110.88.81.51",
      "type": 3,
      "_userId": 39,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 82,
      "firstName": "Xander",
      "lastName": "Cherotich",
      "email": "xandercherotich@example.com",
      "userName": "xandercherotich",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "01/07/1993",
      "ipAddress": "231.204.210.60",
      "type": 1,
      "_userId": 49,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 83,
      "firstName": "Chris",
      "lastName": "Waithaka",
      "email": "chriswaithaka@example.com",
      "userName": "chriswaithaka",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "07/22/1969",
      "ipAddress": "89.126.210.147",
      "type": 2,
      "_userId": 33,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 84,
      "firstName": "Angela",
      "lastName": "Wacera",
      "email": "angelawacera@example.com",
      "userName": "angelawacera",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "10/07/1957",
      "ipAddress": "213.215.135.140",
      "type": 1,
      "_userId": 7,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 85,
      "firstName": "Ursula",
      "lastName": "Cheptais",
      "email": "ursulacheptais@example.com",
      "userName": "ursulacheptais",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "12/15/1984",
      "ipAddress": "113.58.65.138",
      "type": 3,
      "_userId": 34,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 86,
      "firstName": "Thomas",
      "lastName": "Chemlany",
      "email": "thomaschemlany@example.com",
      "userName": "thomaschemlany",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "03/18/1988",
      "ipAddress": "149.124.12.229",
      "type": 4,
      "_userId": 97,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 87,
      "firstName": "Brian",
      "lastName": "Kimobwa",
      "email": "briankimobwa@example.com",
      "userName": "briankimobwa",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "12/02/1950",
      "ipAddress": "2.0.142.75",
      "type": 5,
      "_userId": 39,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 88,
      "firstName": "Timothy",
      "lastName": "Kasimili",
      "email": "timothykasimili@example.com",
      "userName": "timothykasimili",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "11/26/1999",
      "ipAddress": "241.9.55.238",
      "type": 2,
      "_userId": 49,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 89,
      "firstName": "Robert",
      "lastName": "Kwalia",
      "email": "robertkwalia@example.com",
      "userName": "robertkwalia",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "05/02/1957",
      "ipAddress": "103.223.191.186",
      "type": 1,
      "_userId": 11,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 90,
      "firstName": "Alice",
      "lastName": "Kimemia",
      "email": "alicekimemia@example.com",
      "userName": "alicekimemia",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "10/22/1974",
      "ipAddress": "167.75.215.125",
      "type": 3,
      "_userId": 15,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 91,
      "firstName": "Frank",
      "lastName": "Kinyanjui",
      "email": "frankkinyanjui@example.com",
      "userName": "frankkinyanjui",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "06/14/1961",
      "ipAddress": "39.219.231.89",
      "type": 3,
      "_userId": 86,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 92,
      "firstName": "Mark",
      "lastName": "Mutai",
      "email": "markmutai@example.com",
      "userName": "markmutai",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "04/21/1966",
      "ipAddress": "245.245.153.168",
      "type": 1,
      "_userId": 41,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 93,
      "firstName": "Ian",
      "lastName": "Jepkesho",
      "email": "ianjepkesho@example.com",
      "userName": "ianjepkesho",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "06/16/1998",
      "ipAddress": "39.68.198.205",
      "type": 1,
      "_userId": 73,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 94,
      "firstName": "Rachel",
      "lastName": "Ngugi",
      "email": "rachelngugi@example.com",
      "userName": "rachelngugi",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "06/06/1959",
      "ipAddress": "103.53.155.81",
      "type": 1,
      "_userId": 39,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 95,
      "firstName": "Henry",
      "lastName": "Kiogora",
      "email": "henrykiogora@example.com",
      "userName": "henrykiogora",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "04/20/1950",
      "ipAddress": "57.33.1.84",
      "type": 4,
      "_userId": 10,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 96,
      "firstName": "Isabella",
      "lastName": "Bethwell",
      "email": "isabellabethwell@example.com",
      "userName": "isabellabethwell",
      "gender": "Male",
      "status": 0,
      "dateOfBirth": "11/05/1968",
      "ipAddress": "150.255.97.228",
      "type": 3,
      "_userId": 39,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 97,
      "firstName": "Patrick",
      "lastName": "Ndungu",
      "email": "patrickndungu@example.com",
      "userName": "patrickndungu",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "01/17/1971",
      "ipAddress": "216.225.122.127",
      "type": 1,
      "_userId": 87,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 98,
      "firstName": "Ethan",
      "lastName": "Wangari",
      "email": "ethanwangari@example.com",
      "userName": "ethanwangari",
      "gender": "Female",
      "status": 1,
      "dateOfBirth": "07/10/1982",
      "ipAddress": "16.31.61.51",
      "type": 2,
      "_userId": 75,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 99,
      "firstName": "John",
      "lastName": "Komen",
      "email": "johnkomen@example.com",
      "userName": "johnkomen",
      "gender": "Female",
      "status": 0,
      "dateOfBirth": "06/28/1994",
      "ipAddress": "23.185.23.186",
      "type": 3,
      "_userId": 27,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  },
  {
      "id": 100,
      "firstName": "Samuel",
      "lastName": "Kwambai",
      "email": "samuelkwambai@example.com",
      "userName": "samuelkwambai",
      "gender": "Male",
      "status": 1,
      "dateOfBirth": "04/05/1987",
      "ipAddress": "253.101.41.109",
      "type": 1,
      "_userId": 22,
      "_createdDate": "06/06/2024",
      "_updatedDate": "06/06/2024"
  }
];