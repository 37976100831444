import farmerTableMock from "./farmerTableMock";
import MockUtils from "./mock.utils";

export default function mockFarmer(mock) {
  mock.onPost("api/farmers").reply(({ data }) => {
    const { farmer } = JSON.parse(data);
    const {
      firstName = "",
      lastName = "",
      email = "",
      farmerName = "",
      gender = "Female",
      status = 0,
      dateOfBbirth = "01/01/2019",
      ipAddress = "127.0.0.1",
      type = 1
    } = farmer;

    const id = generateFarmerId();
    const newFarmer = {
      id,
      firstName,
      lastName,
      email,
      farmerName,
      gender,
      status,
      dateOfBbirth,
      ipAddress,
      type
    };
    farmerTableMock.push(newFarmer);
    return [200, { farmer: newFarmer }];
  });

  mock.onPost("api/farmers/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdFarmers = mockUtils.baseFilter(
      farmerTableMock,
      queryParams
    );
    return [200, filterdFarmers];
  });

  mock.onPost("api/farmers/deleteFarmers").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = farmerTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        farmerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/farmers/updateStatusForFarmers").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    farmerTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/farmers\/\d+/).reply(config => {
    const id = config.url.match(/api\/farmers\/(\d+)/)[1];
    const farmer = farmerTableMock.find(el => el.id === +id);
    if (!farmer) {
      return [400];
    }

    return [200, farmer];
  });

  mock.onPut(/api\/farmers\/\d+/).reply(config => {
    const id = config.url.match(/api\/farmers\/(\d+)/)[1];
    const { farmer } = JSON.parse(config.data);
    const index = farmerTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    farmerTableMock[index] = { ...farmer };
    return [200];
  });

  mock.onDelete(/api\/farmers\/\d+/).reply(config => {
    const id = config.url.match(/api\/farmers\/(\d+)/)[1];
    const index = farmerTableMock.findIndex(el => el.id === +id);
    farmerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateFarmerId() {
  const ids = farmerTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
