/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from 'react';
import objectPath from 'object-path';
import { Link, useLocation } from 'react-router-dom';
// import AssessmentIcon from '@material-ui/icons/Assessment';
import { BreadCrumbs } from './components/BreadCrumbs';
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from '../../_core/AgriVoucherSubheader';
import { useHtmlClassService } from '../../_core/AgriVoucherLayout';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [back, setBack] = useState('');
  const [backTooltip, setBackTooltip] = useState('');

  const backPaths = [
    { test: '/branches/stats/', back: '/branches/all', title: 'Branches' },
    { test: '/agents/stats/', back: '/agents/all', title: 'Agents' },
    { test: '/groups/stats/', back: '/groups/all', title: 'Properties' },
  ];

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        'subheader.mobile-toggle',
      ),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses(
        'subheader_container',
        true,
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const currPath = location.pathname;
    const aside = getBreadcrumbsAndTitle(
      'kt_aside_menu',
      location.pathname,
    );
    const header = getBreadcrumbsAndTitle(
      'kt_header_menu',
      location.pathname,
    );
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title,
    );
    backPaths.forEach(function (uri) {
      if (currPath.includes(uri.test)) {
        setBack(uri.back);
        setBackTooltip(uri.title);
      } else if (currPath.includes(uri.back)) {
        setBackTooltip('');
        setBack('');
      }
    });
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5
              className="text-dark- font-weight-bold my-2 mr-5"
              style={{ color: 'var(--cs-secondary)' }}
            >
              <>
                {back && (
                  <OverlayTrigger
                    key={'bottom'}
                    placement={'bottom'}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {backTooltip !== '' && (
                          <>
                            Go back to{' '}
                            <strong>
                              {backTooltip}
                            </strong>
                          </>
                        )}
                      </Tooltip>
                    }
                  >
                    <Link to={back} className="mr-5">
                      <span
                        className={
                          'svg-icon menu-icon svg-icon-xl svg-icon-dark-75'
                        }
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            '/media/svg/icons/Navigation/Left-2.svg',
                          )}
                        />
                      </span>
                    </Link>
                  </OverlayTrigger>
                )}
                {subheader.title}
              </>
            </h5>
          </div>

          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>

        {/* Toolbar */}
        {/* <div className="d-flex align-items-center">
            <a href="#" className="btn btn-secondary rounded-pill btn-md font-weight-bold d-flex" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
                 <AssessmentIcon style={{fontSize:'1.2em'}} className="mr-4" />
              <span className="font-weight-bold mr-2">Go to Reports</span>
            </a>
          </div> */}
      </div>
    </div>
  );
}
