/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* <li
          className={`menu-item mb-5 ${getMenuItemActive(
            '/onboard',
            false,
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link onboard" to="/onboard">
            <span className="svg-icon menu-icon svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Navigation/Plus.svg',
                )}
              />
            </span>
            <span className="menu-text font-weight-bolder text-primary">
              Onboarding
            </span>
          </NavLink>
        </li> */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-arrange.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/setup", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/setup">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")} />
            </span>
            <span className="menu-text">Setup Wizard</span>
          </NavLink>
        </li> */}
        
        <li
          className={`menu-item ${getMenuItemActive("/vouchers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/vouchers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Vouchers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/products", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/products">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
            </span>
            <span className="menu-text">Products</span>
          </NavLink>
        </li>
        
        <li
          className={`menu-item ${getMenuItemActive("/farmers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/farmers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Farmers</span>
          </NavLink>
        </li>
        
        <li
          className={`menu-item ${getMenuItemActive("/agrovets", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/agrovets">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">Agrovet Suppliers</span>
          </NavLink>
        </li>
        
  
        
        <li
          className={`menu-item ${getMenuItemActive("/users", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        
        <li
          className={`menu-item ${getMenuItemActive("/reports", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reports">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")} />
            </span>
            <span className="menu-text">Reports</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
